import React, { ReactNode, createContext, useContext, useMemo } from "react";
import { isMatch } from "lodash-es";

type ContextValue = {
	readonly inventoryTypesBlacklist: readonly Record<string, unknown>[];
};

const Context = createContext<ContextValue | undefined>(undefined);

type InventoryTypesBlacklistProviderProps = {
	readonly inventoryTypesBlacklist: readonly Record<string, unknown>[];
	readonly children: ReactNode;
};

function InventoryTypesBlacklistProvider({
	inventoryTypesBlacklist,
	children,
}: InventoryTypesBlacklistProviderProps) {
	const value = useMemo(
		() => ({
			inventoryTypesBlacklist,
		}),
		[inventoryTypesBlacklist],
	);
	return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useInventoryTypesBlacklist() {
	const value = useContext(Context);
	if (!value) {
		throw new Error("No shopify url context");
	}
	return {
		allowInventoryType(type: Record<string, unknown>) {
			return value.inventoryTypesBlacklist.some((t) => isMatch(t, type));
		},
	};
}

export { InventoryTypesBlacklistProvider, useInventoryTypesBlacklist };
