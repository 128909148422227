import { gql } from "@apollo/client";

const fullInventoryStateFragment = gql`
	fragment fullInventoryState on AdminInventoryState {
		items {
			type {
				type
				size
				colourId
				containerId
			}
			cost
			quantity
			history {
				cost
				quantity
			}
		}
	}
`;

export default fullInventoryStateFragment;
