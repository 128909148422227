import { stringify as stringifyCsv } from "csv-stringify/browser/esm/sync";
import { parse as parseCsv } from "csv-parse/browser/esm/sync";
import { Container, PaletteColour } from "../inventory-shared/data.ts";

function createImportTemplate(
	basePlateSizes: readonly number[],
	colours: readonly PaletteColour[],
	containers: readonly Container[],
) {
	const sheet = [
		["Colours", ...containers.map((c) => c.identifier)],
		...colours.map((c) => [c.id.toString()]),
		[],
		basePlateSizes.flatMap((s) => [`BP ${s}x${s}`, ""]),
		["Paper A5", "", "Paper A4", "", "Paper A3", "", "Paper Letter", ""],
		[
			"Brick separators",
			"",
			"Packaging",
			"",
			"Small flatpack box",
			"",
			"Logo tile",
			"",
		],
		[
			"Padded envelope",
			"",
			"Single box",
			"",
			"Double box",
			"",
			"Triple box",
			"",
		],
	];
	const maxLength = Math.max(...sheet.map((s) => s.length));
	const consistentSheet = sheet.map((s) => [
		...s,
		...Array(maxLength - s.length).fill(""),
	]);
	return stringifyCsv(consistentSheet);
}

async function fileToString(file: File): Promise<string> {
	const reader = new FileReader();
	return new Promise((resolve, reject) => {
		reader.onload = (event) => {
			if (!event.target?.result) {
				reject(new Error("Couln't read file"));
				return;
			}
			const {
				target: { result },
			} = event;
			if (typeof result === "string") {
				resolve(result);
				return;
			}

			const decoder = new TextDecoder("utf-8");
			resolve(decoder.decode(result));
		};
		reader.readAsText(file);
	});
}

async function parseImportCsv(
	content: string,
): Promise<readonly (readonly string[])[]> {
	return new Promise((resolve, reject) => {
		try {
			const result = parseCsv(content);
			resolve(result);
		} catch (e) {
			reject(e);
		}
	});
}

function importValueToNumber(
	value: string | undefined | null,
): number | undefined {
	if (value === "" || value === null || value === undefined) {
		return undefined;
	}
	const proposed = parseFloat(value);
	if (Number.isNaN(proposed)) {
		return undefined;
	}
	return proposed;
}

async function importToValues(
	basePlateSizes: readonly number[],
	colours: readonly PaletteColour[],
	containers: readonly Container[],
	file: File,
): Promise<Record<string, unknown>> {
	const content = await fileToString(file);
	const res = await parseImportCsv(content);
	const baseplatesY = colours.length + 2;
	const paperY = baseplatesY + 1;
	const otherY = baseplatesY + 2;
	const packagingY = baseplatesY + 3;

	return {
		containers: Object.fromEntries(
			containers.map((container, i) => [
				container.identifier,
				[
					undefined,
					...colours.map((_, j) => importValueToNumber(res[j + 1][i + 1])),
				],
			]),
		),

		baseplates: Object.fromEntries(
			basePlateSizes.map((size, i) => [size, res[baseplatesY][2 * i + 1]]),
		),

		paperA5: importValueToNumber(res[paperY][1]),
		paperA4: importValueToNumber(res[paperY][3]),
		paperA3: importValueToNumber(res[paperY][5]),
		paperLetter: importValueToNumber(res[paperY][7]),

		brickSeparators: importValueToNumber(res[otherY][1]),
		packaging: importValueToNumber(res[otherY][3]),
		smallFlatpackBox: importValueToNumber(res[otherY][5]),
		logoTile: importValueToNumber(res[otherY][7]),

		paddedEnvelope: importValueToNumber(res[packagingY][1]),
		singleBox: importValueToNumber(res[packagingY][3]),
		doubleBox: importValueToNumber(res[packagingY][5]),
		tripleBox: importValueToNumber(res[packagingY][7]),
	};
}

export { createImportTemplate, importToValues };
