import { sum } from "lodash-es";
import { InventoryState, InventoryStateItem } from "~/api/inventory-state.ts";

function calculateItemTotalCost(item: InventoryStateItem): number {
	return sum(
		item.history.map(
			(h) =>
				h.quantity *
				(h.cost === undefined || h.cost === null ? 0 : parseFloat(h.cost)),
		),
	);
}

function calculateStateTotalCost(state: InventoryState): number {
	return sum(state.items.map(calculateItemTotalCost));
}

export { calculateItemTotalCost, calculateStateTotalCost };
