import React, {
	useState,
	ReactNode,
	ReactNodeArray,
	useCallback,
	useMemo,
} from "react";
import { SimpleForm, NumberInput, Validator, number } from "react-admin";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { InventoryState, InventoryStateItem } from "~/api/inventory-state.ts";
import Modal from "~/components/modal.tsx";
import { useInventoryBasePlateSizes } from "~/hooks/base-plate-sizes.tsx";
import { useInventoryTypesBlacklist } from "~/hooks/inventory.tsx";
import { Container, PaletteColour } from "../inventory-shared/data.ts";
import { formatInventoryStateCost as baseFormatInventoryStateCost } from "../inventory-shared/format-inventory-state-item.ts";
import ColourContainerTable from "../inventory-shared/colour-container-table.tsx";
import ImportToolbar from "./import-toolbar.tsx";

type InventoryFormProps = {
	readonly onSave: (input: any) => void;
	readonly saving: boolean;
	readonly children?: ReactNode | ReactNodeArray;
	readonly cellValidate?: Validator | Validator[];
	readonly cellStep?: number;
	readonly colours: readonly PaletteColour[];
	readonly containers: readonly Container[];
	readonly inventoryState?: InventoryState;
	readonly actionName: string;
};

function InventoryForm({
	colours,
	containers,
	inventoryState,
	onSave,
	saving,
	children,
	cellStep,
	cellValidate,
	actionName,
}: InventoryFormProps) {
	const basePlateSizes = useInventoryBasePlateSizes();

	const formatInventoryStateCost = useCallback(
		(getter: (s: InventoryState) => InventoryStateItem | undefined) => {
			if (!inventoryState) {
				return "";
			}
			return baseFormatInventoryStateCost(getter(inventoryState));
		},
		[inventoryState],
	);

	const allCellValidate = useMemo(() => {
		const base = [number()];
		if (!cellValidate) {
			return base;
		}
		if (Array.isArray(cellValidate)) {
			return [...base, ...cellValidate];
		}
		return [...base, cellValidate];
	}, [cellValidate]);

	const renderCell = useCallback(
		(colour: PaletteColour, container: Container) => (
			<NumberInput
				source={`containers.${container.identifier}.${colour.id}`}
				validate={allCellValidate}
				step={cellStep}
				label={formatInventoryStateCost((s) =>
					s.items.find(
						(c) =>
							c.type.type === "container" &&
							c.type.colourId?.toString() === colour.id.toString() &&
							c.type.containerId === container.identifier,
					),
				)}
				helperText={false}
				disabled={saving}
			/>
		),
		[formatInventoryStateCost, cellStep, allCellValidate, saving],
	);
	const { allowInventoryType } = useInventoryTypesBlacklist();

	const [pendingSaveValues, setPendingValues] = useState<any>();
	const onConfirmClick = () => {
		const {
			containers: containersValues,
			baseplates: baseplatesValues,
			...restValues
		} = pendingSaveValues;
		onSave({
			...Object.fromEntries(
				Object.entries(restValues).filter(([, v]) => v !== null),
				// Date and reason will be passed through which aren't nums
				// .map(([k, v]) => [k, castValueToNumber(v)])
			),
			baseplates: Object.entries(baseplatesValues ?? {}).flatMap(
				([size, value]: any) => ({
					size,
					value,
				}),
			),
			containers: Object.entries(containersValues ?? {}).flatMap(
				([containerId, values]: any) =>
					values
						.map((value: any, colourId: any) => ({
							value,
							colourId,
							containerId,
						}))
						.filter(({ value }: any) => value !== undefined),
			),
		});
		setPendingValues(undefined);
	};

	return (
		<>
			<Modal
				id="pending-save"
				open={!!pendingSaveValues}
				onClose={() => setPendingValues(undefined)}
				title="Are you sure?"
				description={`This action will ${actionName}. Are you sure you want to continue?`}
				buttons={[
					<Button
						key="no"
						type="button"
						color="secondary"
						variant="outlined"
						onClick={() => setPendingValues(undefined)}
					>
						No
					</Button>,
					<Button
						key="yes"
						type="button"
						color="primary"
						variant="contained"
						onClick={onConfirmClick}
					>
						Yes
					</Button>,
				]}
			/>
			<SimpleForm save={setPendingValues} saving={saving}>
				<ImportToolbar colours={colours} containers={containers} />
				<ColourContainerTable
					colours={colours}
					containers={containers}
					renderCell={renderCell}
				/>
				<div
					style={{ display: "flex", flexDirection: "column", width: "100%" }}
				>
					<Typography component="h3">Baseplates</Typography>
					<div
						style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
					>
						{basePlateSizes.map((size) => (
							<div key={size}>
								<Typography component="h4">
									{size}x{size}
								</Typography>
								<NumberInput
									source={`baseplates.${size}`}
									validate={allCellValidate}
									step={cellStep}
									label={formatInventoryStateCost((s) =>
										s.items.find(
											(b) =>
												b.type.type === "baseplate" && b.type.size === size,
										),
									)}
									helperText={false}
									disabled={saving}
								/>
							</div>
						))}
					</div>
					<Typography component="h3">Paper</Typography>
					<div
						style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
					>
						{allowInventoryType({ type: "paperA5" }) && (
							<div>
								<Typography component="h4">A5</Typography>
								<NumberInput
									source="paperA5"
									validate={allCellValidate}
									step={cellStep}
									label={formatInventoryStateCost((s) =>
										s.items.find((i) => i.type.type === "paperA5"),
									)}
									helperText={false}
									disabled={saving}
								/>
							</div>
						)}
						{allowInventoryType({ type: "paperA4" }) && (
							<div>
								<Typography component="h4">A4</Typography>
								<NumberInput
									source="paperA4"
									validate={allCellValidate}
									step={cellStep}
									label={formatInventoryStateCost((s) =>
										s.items.find((i) => i.type.type === "paperA4"),
									)}
									helperText={false}
									disabled={saving}
								/>
							</div>
						)}
						{allowInventoryType({ type: "paperLetter" }) && (
							<div>
								<Typography component="h4">Letter</Typography>
								<NumberInput
									source="paperLetter"
									validate={allCellValidate}
									step={cellStep}
									label={formatInventoryStateCost((s) =>
										s.items.find((i) => i.type.type === "paperLetter"),
									)}
									helperText={false}
									disabled={saving}
								/>
							</div>
						)}
						{allowInventoryType({ type: "paperA3" }) && (
							<div>
								<Typography component="h4">A3</Typography>
								<NumberInput
									source="paperA3"
									validate={allCellValidate}
									step={cellStep}
									label={formatInventoryStateCost((s) =>
										s.items.find((i) => i.type.type === "paperA3"),
									)}
									helperText={false}
									disabled={saving}
								/>
							</div>
						)}
					</div>
					<Typography component="h3">&nbsp;</Typography>
					<div
						style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
					>
						<div>
							<Typography component="h4">Brick separators</Typography>
							<NumberInput
								source="brickSeparators"
								label={formatInventoryStateCost((s) =>
									s.items.find((i) => i.type.type === "brickSeparators"),
								)}
								validate={allCellValidate}
								step={cellStep}
								helperText={false}
								disabled={saving}
							/>
						</div>
						<div>
							<Typography component="h4">Logo tile</Typography>
							<NumberInput
								source="logoTile"
								label={formatInventoryStateCost((s) =>
									s.items.find((i) => i.type.type === "logoTile"),
								)}
								validate={allCellValidate}
								step={cellStep}
								helperText={false}
								disabled={saving}
							/>
						</div>
						<div>
							<Typography component="h4">Packaging</Typography>
							<NumberInput
								source="packaging"
								label={formatInventoryStateCost((s) =>
									s.items.find((i) => i.type.type === "packaging"),
								)}
								validate={allCellValidate}
								step={cellStep}
								helperText={false}
								disabled={saving}
							/>
						</div>
						<div>
							<Typography component="h4">Small flatpack box</Typography>
							<NumberInput
								source="smallFlatpackBox"
								label={formatInventoryStateCost((s) =>
									s.items.find((i) => i.type.type === "smallFlatpackBox"),
								)}
								validate={allCellValidate}
								step={cellStep}
								helperText={false}
								disabled={saving}
							/>
						</div>
					</div>
					<Typography component="h3">&nbsp;</Typography>
					<div
						style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
					>
						{allowInventoryType({ type: "paddedEnvelope" }) && (
							<div>
								<Typography component="h4">Padded envelope</Typography>
								<NumberInput
									source="paddedEnvelope"
									label={formatInventoryStateCost((s) =>
										s.items.find((i) => i.type.type === "paddedEnvelope"),
									)}
									validate={allCellValidate}
									step={cellStep}
									helperText={false}
									disabled={saving}
								/>
							</div>
						)}
						{allowInventoryType({ type: "singleBox" }) && (
							<div>
								<Typography component="h4">Single box</Typography>
								<NumberInput
									source="singleBox"
									label={formatInventoryStateCost((s) =>
										s.items.find((i) => i.type.type === "singleBox"),
									)}
									validate={allCellValidate}
									step={cellStep}
									helperText={false}
									disabled={saving}
								/>
							</div>
						)}
						{allowInventoryType({ type: "doubleBox" }) && (
							<div>
								<Typography component="h4">Double box</Typography>
								<NumberInput
									source="doubleBox"
									label={formatInventoryStateCost((s) =>
										s.items.find((i) => i.type.type === "doubleBox"),
									)}
									validate={allCellValidate}
									step={cellStep}
									helperText={false}
									disabled={saving}
								/>
							</div>
						)}
						{allowInventoryType({ type: "tripleBox" }) && (
							<div>
								<Typography component="h4">Triple box</Typography>
								<NumberInput
									source="tripleBox"
									label={formatInventoryStateCost((s) =>
										s.items.find((i) => i.type.type === "tripleBox"),
									)}
									validate={allCellValidate}
									step={cellStep}
									helperText={false}
									disabled={saving}
								/>
							</div>
						)}
					</div>
				</div>
				{children}
			</SimpleForm>
		</>
	);
}

function inventoryFormValuesToApi(
	basePlateSizes: readonly number[],
	{ baseplates, containers, ...variablesRest }: any,
) {
	return {
		...variablesRest,
		containers: containers
			? containers.filter((c: any) => c.value !== null)
			: undefined,
		baseplates: basePlateSizes
			.map((size) => ({
				size,
				value: parseInt(
					baseplates.find((b: any) => b.size === size.toString())?.value,
					10,
				),
			}))
			.filter((b) => !Number.isNaN(b.value)),
	};
}

export type { InventoryFormProps };
export { inventoryFormValuesToApi };
export default InventoryForm;
